import React, { useEffect, useRef, useState } from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import LoadingAnimation from "../../../common/Loader";
import ThemeSchema from "../../../../theme/schema";
import {
  ActionStatusType,
  fetchDeviceActionLatency,
  fetchDeviceActionLatencyStates,
} from "../../../../BytebeamClient";
import { LatencyHistogramStats } from "../../../../util";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import { capitalizeFirstLetter } from "../../util";

type ActionsLatencyHistogramProps = {
  readonly selectedAction: ActionStatusType;
};

export function ActionsLatencyHistogram(props: ActionsLatencyHistogramProps) {
  const { selectedAction } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const [y, setY] = useState<number[]>([]);
  const [xStart, setXStart] = useState<number[]>([]);
  const [xEnd, setXEnd] = useState<number[]>([]);
  const [actionStates, setActionStates] = useState<string[]>([]);
  const [actionState, setActionState] = useState<string>("");
  const [currentActionId, setCurrentActionId] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataLoading, setDataLoading] = useState<boolean>(false); // state to track data loading seperately from component loading

  const controllerRef = useRef(new AbortController());

  // Fetch the action states when the component mounts or the selectedAction changes
  useEffect(() => {
    const actionId = selectedAction.action_id;
    let isMounted = true;
    let timeoutId: NodeJS.Timeout | null = null;
    let abortController = controllerRef.current;

    // Initial fetch of action states when action changes and set the first state by default
    const initialFetchActionStates = async () => {
      try {
        const states = await fetchDeviceActionLatencyStates(
          actionId,
          abortController.signal
        );
        if (isMounted && states.length > 0) {
          // ensuring states array is not empty before setting the states
          setActionStates(states);

          // Setting to first state by default
          setActionState(states[0]);
        }
      } catch (error) {
        console.error("Failed to fetch action states:", error);
      }
    };
    initialFetchActionStates();

    // Fetch action states every 30 seconds to get updated states
    const refreshFetchActionStates = async () => {
      if (
        !isMounted ||
        (currentActionId !== actionId && currentActionId !== -1)
      ) {
        return;
      }

      try {
        const states = await fetchDeviceActionLatencyStates(
          actionId,
          abortController.signal
        );
        if (isMounted) {
          setActionStates(states);
          // Check if current state is still valid, if not, reset to first state, ensures that we have a valid state
          if (states.length > 0 && !states.includes(actionState)) {
            setActionState(states[0]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch action states:", error);
      } finally {
        if (isMounted) {
          setTimeout(() => {
            refreshFetchActionStates();
          }, 5 * 1000); // Polling every 5 seconds
        }
      }
    };

    refreshFetchActionStates();
    setCurrentActionId(actionId);

    return () => {
      isMounted = false;
      setCurrentActionId(-1);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [selectedAction.action_id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch latency stats when actionState changes (or periodically, if needed)
  useEffect(() => {
    // Returning early if actionState is not set, preventing unnecessary API calls and loading states
    if (!actionState) {
      setLoading(false);
      return;
    }
    setLoading(true);

    const actionId = selectedAction.action_id;
    let isMounted = true;
    let timeoutId: NodeJS.Timeout | null = null;
    let abortController = controllerRef.current;

    const fetchLatencyStats = async () => {
      if (
        !isMounted ||
        (currentActionId !== actionId && currentActionId !== -1)
      ) {
        return;
      }

      try {
        const latencyStats: LatencyHistogramStats =
          await fetchDeviceActionLatency(
            actionId,
            actionState,
            abortController.signal
          );
        if (isMounted) {
          const filteredStats = latencyStats.filter((stat) => stat.height > 0);

          setXStart(filteredStats.map((stat) => stat.range_start));
          setXEnd(filteredStats.map((stat) => stat.range_end));
          setY(filteredStats.map((stat) => stat.height));
        }
      } catch (error) {
        console.error("Failed to fetch latency stats:", error);
        setY([]);
        setXStart([]);
        setXEnd([]);
      } finally {
        // Resetting both loading states after fetch completes
        setLoading(false);
        setDataLoading(false);
        if (isMounted) {
          timeoutId = setTimeout(() => {
            fetchLatencyStats();
          }, 5 * 1000); // Polling every 5 seconds
        }
      }
    };

    fetchLatencyStats(); // data is always fetched when actionState changes

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [selectedAction.action_id, actionState, currentActionId]);

  if (loading) {
    return (
      <LoadingAnimation
        loaderSize="28px"
        fontSize="18px"
        loaderBorderSize="3px"
        marginTopText="6px"
        loadingText="Loading latency histogram..."
      />
    );
  } else if (actionStates.length === 0) {
    // dedicated condition to handle when actionStates array is empty
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        No action states available
      </div>
    );
  } else {
    // Calculate the center of each bin
    const xCenter = xStart.map((start, i) => (start + xEnd[i]) / 2);
    const widths = xEnd.map((end, i) => end - xStart[i]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "row",
            gap: "6px",
            margin: "8px 0px",
            width: "100%",
          }}
        >
          {actionStates.map((status) => (
            <ActionButtonV3
              key={status}
              onClick={() => setActionState(status)} // Update actionState when a tab is clicked
              label={capitalizeFirstLetter(status)}
              selected={actionState === status}
              margin_left={"0px"}
              padding={"0rem .5rem"}
              button_height={"24px"}
            />
          ))}
        </div>
        {dataLoading && ( // conditional rendering for data loading state
          <div
            style={{
              width: "100%",
              height: 180,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingAnimation // seperate loading indicator for data loading state
              loaderSize="28px"
              fontSize="18px"
              loaderBorderSize="3px"
              marginTopText="6px"
              loadingText="Loading data..."
            />
          </div>
        )}

        {!dataLoading && y.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: 180,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Latency Histogram is not available for{" "}
            {capitalizeFirstLetter(actionState)}
          </div>
        ) : (
          <Plot
            style={{
              width: "100%",
              height: 180,
            }}
            data={[
              {
                name: "",
                x: xCenter, // Use the center of each bin
                y,
                width: widths,
                type: "bar",
                orientation: "v",
                marker: {
                  showscale: false,
                  color: "#4683fc",
                  line: {
                    color: "rgb(22, 68, 136)",
                    width: 1,
                  },
                },
                showlegend: false,
                hoverinfo: "x+y",
                customdata: xStart.map((start, i) => [
                  start?.toFixed(3),
                  xEnd[i]?.toFixed(3),
                ]),
                hovertemplate:
                  "Start Time: %{customdata[0]} ms<br>End Time: %{customdata[1]} ms<br>Devices: %{y}",
                hoverlabel: { namelength: -1 },
              },
            ]}
            useResizeHandler
            layout={{
              autosize: true,
              margin: {
                l: 42,
                r: 12,
                b: 36,
                t: 18,
              },
              xaxis: {
                automargin: true,
                color:
                  ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
                showline: true,
                showgrid: true,
                fixedrange: true,
                tickmode: "auto",
                title: "Time (milliseconds)",
                range: [
                  xStart.length > 0 ? xStart[0] : 0,
                  Math.max(...xEnd) +
                    (xEnd.length > 1 ? xEnd[1] - xEnd[0] : 10),
                ],
              },
              yaxis: {
                color:
                  ThemeSchema.data[theme ?? "dark"]?.colors["chart-text-color"],
                showline: true,
                showgrid: true,
                fixedrange: true,
                range: [0, Math.max(...y)],
                title: "Devices",
              },
              paper_bgcolor: "transparent",
              plot_bgcolor: "transparent",
            }}
            config={{
              displayModeBar: false,
            }}
          />
        )}
      </div>
    );
  }
}
