import React, {
  memo,
  useState,
  useCallback,
  useEffect,
  MouseEvent,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Divider, Icon, MenuItem, PaginationProps } from "semantic-ui-react";
import { SelectDevicesPerPage } from "../../DeviceManagement/Devices/Devices";
import DeviceListV3 from "./DeviceListV3";
import { StyledHeader } from "../ActionsV3/SelectableItem";

import {
  ActionStatusDetail,
  ActionStatusType,
  devicesPerPageOptions,
  fetchAction,
  fetchDeviceActionStatusDetailsWithPhase,
} from "../../../../BytebeamClient";
import {
  ButtonPosition,
  deviceStagesList,
  getSelectedPhaseIndex,
  inProgressStatuses,
} from "../util";
import { Permission, validateWholeNumber } from "../../../../util";
import { useUser } from "../../../../context/User.context";
import { ThinDivider } from "../../Dashboards/Panel/util";
import styled from "styled-components";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import { beamtoast } from "../../../common/CustomToast";
import { CardContainer, FlexContainer } from "../../../common/ActionsUtils";
import {
  NewActionLabelContainer,
  StyledNonBoldHeader,
} from "../NewAction/NewAction";
import ActionFilterDropdown, {
  FilteredValue,
} from "../ActionsV3/ActionFilterDropdown";
import SelectedFilter from "../ActionsV3/SelectedFilter";
import { debounce } from "../../util";
import SelectedDevicesAction from "./SelectedDevicesAction";

const AddFiltersButton = styled(Icon)`
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 15px !important;
  cursor: pointer;
`;

type DeviceListViewProps = {
  readonly actionID: string;
  readonly actionsData: ActionStatusType;
  readonly selectedPhase: string;
  setSelectedAction: (action: any) => void;
};

type DeviceFilter = {
  filter: string;
  color: string;
};

const DeviceListView = forwardRef((props: DeviceListViewProps, ref) => {
  // Expose the refetch function to the parent component, so that it can be called from the parent component.
  useImperativeHandle(ref, () => ({
    handleRefetchDeviceStatusDetails,
  }));

  const { actionID, actionsData, setSelectedAction, selectedPhase } = props;
  const [devicesLoading, setDevicesLoading] = useState<boolean>(true);
  const [deviceItems, setDeviceItems] = useState<ActionStatusDetail[]>([]);
  const [devicesPageNumber, setDevicesPageNumber] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState(0);
  const [numTotal, setNumTotal] = useState<number>(0);
  const [devicesPageLimit, setDevicesPageLimit] = useState<number>(10);

  const [allDevicesSelected, setAllDevicesSelected] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<
    { deviceID: number; deviceStatus: string }[]
  >([]);
  const [selectedDeviceCount, setSelectedDeviceCount] = useState<number>(0);
  const [allDevicesCountCurrentPhase, setAllDevicesCountCurrentPhase] =
    useState<number>(0);

  // for device list filtering
  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showFilterButtonDropdown, setShowFilterButtonDropdown] =
    useState<boolean>(false);
  const [stagesList, setStagesList] =
    useState<FilteredValue[]>(deviceStagesList);
  const [filters, setFilters] = useState<DeviceFilter[]>([]);
  const [selectedStages, setSelectedStages] = useState<string[]>(["all"]);
  const [hideFilterButton, setHideFilterButton] = useState<boolean>(true);
  // flag to track the background/auto refresh
  const [isBackgroundRefresh, setIsBackgroundRefresh] = useState(false);

  // for calculating dropdown position to make it statically positioned
  const [addFiltersButtonPosition, setAddFiltersButtonPosition] =
    useState<ButtonPosition>();

  const resetDeviceSelectionStates = () => {
    setSelectedDevices([]);
    setSelectedDeviceCount(0);
    setAllDevicesSelected(false);
  };

  /**
   * Handles the refetching of the device status details.
   */
  /**
   * Handles the refetching of device status details.
   * @returns {Promise<void>} A promise that resolves when the device status details are fetched.
   */

  // Create a wrapper function to safely set the loading state
  const setLoadingState = useCallback(
    (isLoading) => {
      // Only change loading state if not in background refresh
      if (!isBackgroundRefresh) {
        setDevicesLoading(isLoading);
      }
    },
    [isBackgroundRefresh]
  );

  const fetchStatusDetails = useCallback(
    async (devicesPageNumber, devicesPageLimit, status: string | string[]) => {
      const selectedPhaseIndex = getSelectedPhaseIndex(selectedPhase);
      setLoadingState(true);
      try {
        const res = await fetchDeviceActionStatusDetailsWithPhase(
          parseInt(actionID),
          status,
          devicesPageNumber,
          devicesPageLimit,
          selectedPhaseIndex
        );
        setDeviceItems(res.device_actions);
        setAllDevicesCountCurrentPhase(res.count);
        setNumTotal(res.count);
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingState(false);
        setHideFilterButton(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actionID,
      actionsData,
      selectedPhase,
      devicesPageNumber,
      devicesPageLimit,
      setLoadingState,
    ]
  );

  const fetchActionData = useCallback(async () => {
    try {
      const data = await fetchAction(Number(actionID));
      setSelectedAction(data);
      const numTotal = Object.values(data.statuses ?? {}).reduce(
        (a, b) => a + b,
        0
      );
      setNumTotal(numTotal);
    } catch (e) {
      console.error("Error fetching action data:", e);
      throw e; // throwing error to handel in the parent component
    }
  }, [actionID, setSelectedAction]);

  const handleRefetchDeviceStatusDetails =
    useCallback(async (): Promise<void> => {
      try {
        await fetchActionData();
        await fetchStatusDetails(
          devicesPageNumber,
          devicesPageLimit,
          selectedStages
        );
      } catch (error) {
        console.error("Error in handleRefetchDeviceStatusDetails:", error);
      }
    }, [
      devicesPageNumber,
      devicesPageLimit,
      selectedStages,
      fetchActionData,
      fetchStatusDetails,
    ]);

  useEffect(() => {
    let isMounted = true;
    let timer: NodeJS.Timeout | null = null;

    const autoRefresh = async () => {
      // Only refresh if component is still mounted
      if (isMounted) {
        try {
          // Setting the background refresh flag before refreshing
          setIsBackgroundRefresh(true);
          await handleRefetchDeviceStatusDetails();
        } catch (error) {
          console.error("Auto-refresh failed:", error);
        } finally {
          // Reset the background refresh flag after auto refresh
          if (isMounted) {
            setIsBackgroundRefresh(false);
          }
        }

        // Scheduling next refresh if component is still mounted
        if (isMounted) {
          timer = setTimeout(autoRefresh, 5000);
        }
      }
    };

    // Initial delay before starting the refresh cycle
    timer = setTimeout(autoRefresh, 5000);

    // Cleanup function
    return () => {
      isMounted = false;
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [handleRefetchDeviceStatusDetails]);

  /**
   * Handles the click event when the "All Devices Selected" button is clicked.
   * Toggles the state of `allDevicesSelected` and updates the selected devices accordingly.
   * If `allDevicesSelected` is false, it selects all the devices and updates the selected device count and devices.
   * If `allDevicesSelected` is true, it deselects all the devices and sets the selected device count to 0.
   */
  const handleAllDevicesSelectedClick = () => {
    setAllDevicesSelected(!allDevicesSelected);
    if (!allDevicesSelected) {
      // Set all devices in the current phase as selected devices.
      setSelectedDevices(
        deviceItems.map((device) => ({
          deviceID: device.device_id,
          deviceStatus: device.status,
        }))
      );
      // Set device count to the total number of devices for the selected-phase, and set all devices as selected devices.
      setSelectedDeviceCount(allDevicesCountCurrentPhase);
    } else {
      setSelectedDevices([]);
      setSelectedDeviceCount(0);
    }
  };

  /**
   * Handles the click event on the checkbox.
   * Stores the ID of the device and its status in the selectedDevices state.
   * @param {number} deviceID - The ID of the device.
   * @param {string} deviceStatus - The status of the device.
   */
  const handleCheckboxClick = (deviceID: number, deviceStatus: string) => {
    setSelectedDevices((prevSelectedDevices) => {
      const newSelectedDevices = [...prevSelectedDevices];
      const index = newSelectedDevices.findIndex(
        (device) => device.deviceID === deviceID
      );

      if (index > -1) {
        newSelectedDevices.splice(index, 1);
      } else {
        newSelectedDevices.push({ deviceID, deviceStatus });
      }

      setSelectedDeviceCount(newSelectedDevices?.length);
      return newSelectedDevices;
    });
  };

  const { user } = useUser();
  const permissions: Permission = user?.role?.permissions;
  const allowedActions = permissions.allowedActions || [];

  const changeDevicesPerPage = useCallback(
    async (e, data) => {
      try {
        setLoadingState(true);
        setDevicesPageLimit(data.value);
        setDevicesPageNumber(1);
        window.localStorage.setItem("devicesListPerPage", data.value);
        await fetchStatusDetails(1, data.value, selectedStages);
      } catch (e) {
        beamtoast.error("Failed to change the number of devices per page");
        console.error("Error in changeDeviceStatus: ", e);
        // Make sure loading is reset on error
        setLoadingState(false);
      }
    },
    [devicesPageLimit, devicesPageNumber, selectedStages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePaginationInputChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setInputPageNumber(newValue);
    },
    [inputPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handlePaginationInputKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber.toString())) {
        onPaginationChange(event, {
          activePage:
            inputPageNumber && inputPageNumber > 0
              ? inputPageNumber > Math.ceil(numTotal / devicesPageLimit)
                ? Math.ceil(numTotal / devicesPageLimit)
                : inputPageNumber
              : 1,
          totalPages: Math.ceil(numTotal / devicesPageLimit),
        });
        setInputPageNumber(0);
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  /**
   * Updates the total number of devices based on the selected phase.
   * If the phaseName is "all", it calculates the total number of devices across all phases.
   * If the phaseName is a specific phase, it calculates the total number of devices in that phase.
   * @param phaseName - The name of the phase. Use "all" for all phases.
   */
  const handlePhaseSelectTotalPages = useCallback(
    (phaseName: string) => {
      if (phaseName === "all") {
        let totalDevicesInPhase = Object.values(
          actionsData?.statuses ?? {}
        ).reduce((a: number, b: number) => a + b, 0);
        setNumTotal(totalDevicesInPhase);
      } else {
        let phaseStatuses: Record<string, number> = {};
        let phaseIndex = actionsData?.schedule?.phases.findIndex(
          (phase: { name: string }) => phase.name === phaseName
        );
        if (phaseIndex >= 0) {
          phaseStatuses =
            actionsData?.statuses_phased?.[phaseIndex.toString()] || {};
        }
        let totalDevicesInPhase = Object.values(phaseStatuses).reduce(
          (a: number, b: number) => a + b,
          0
        );
        setNumTotal(totalDevicesInPhase);
      }
    },
    [selectedPhase, actionsData] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onPaginationChange = useCallback(
    (event: MouseEvent, data: PaginationProps) => {
      const activePage = data.activePage as number;
      setLoadingState(true);
      setDevicesPageNumber(activePage);
      fetchStatusDetails(activePage, devicesPageLimit, selectedStages);
    },
    [selectedStages, devicesPageLimit, setLoadingState] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const addDeviceFilter = (filter: FilteredValue) => {
    let newColor = "";
    deviceStagesList.forEach((stage) => {
      if (stage.name === filter.name)
        newColor = stage.color ? stage.color : "text"; // fallback color
    });

    setFilters((prev) => [...prev, { filter: filter.name, color: newColor }]);

    let currentSelectedStages = selectedStages;
    if (currentSelectedStages[0] === "all") currentSelectedStages = [];
    if (filter.name === "Pending Approval") filter.name = "PendingApproval"; // edge case :(
    if (filter.name !== "In Progress") {
      setSelectedStages([...currentSelectedStages, filter.name]);
      debouncedfetchStatusDetails(1, devicesPageLimit, [
        ...currentSelectedStages,
        filter.name,
      ]);
    } else {
      setSelectedStages([...currentSelectedStages, ...inProgressStatuses]);
      debouncedfetchStatusDetails(1, devicesPageLimit, [
        ...currentSelectedStages,
        ...inProgressStatuses,
      ]);
    }

    resetDeviceSelectionStates();
    setDevicesPageNumber(1);
  };

  const removeDeviceFilter = (filter: string) => {
    let currentFilters = filters;
    currentFilters = currentFilters.filter(
      (currentFilter) => currentFilter.filter !== filter
    );
    setFilters(currentFilters);

    let currentSelectedStages = selectedStages;
    if (filter === "Pending Approval") filter = "PendingApproval"; // edge case :(
    if (filter !== "In Progress") {
      currentSelectedStages = currentSelectedStages.filter(
        (currentStage) => currentStage !== filter
      );
      if (currentSelectedStages?.length === 0) currentSelectedStages = ["all"];
      setSelectedStages(currentSelectedStages);
      debouncedfetchStatusDetails(1, devicesPageLimit, [
        ...currentSelectedStages,
      ]);
    } else {
      currentSelectedStages = currentSelectedStages.filter(
        (currentStage) => !inProgressStatuses.includes(currentStage)
      );
      setSelectedStages(currentSelectedStages);
      debouncedfetchStatusDetails(1, devicesPageLimit, [
        ...currentSelectedStages,
      ]);
    }
    resetDeviceSelectionStates();
    setDevicesPageNumber(1);
  };

  const debouncedfetchStatusDetails = useCallback(
    debounce(fetchStatusDetails, 1000),
    [fetchStatusDetails]
  );

  /**
   * Resets the filters and fetches the device status details.
   * And to only show the devices that are in the selected phase.
   * @returns {void}
   */
  const resetFilters = useCallback(() => {
    handlePhaseSelectTotalPages(selectedPhase);
    setDevicesPageNumber(1);
    setShowFilterDropdown(false);
    setShowFilterButtonDropdown(false);
    setStagesList(deviceStagesList);
    setFilters([]);
    setSelectedStages(["all"]);
    fetchStatusDetails(1, devicesPageLimit, "all");
  }, [devicesPageLimit, actionsData, selectedPhase]); // eslint-disable-line react-hooks/exhaustive-deps

  const getButtonPosition = (id: string) => {
    const buttonElement = document.getElementById(id);
    if (buttonElement) {
      const buttonPosition = buttonElement.getBoundingClientRect();
      setAddFiltersButtonPosition({
        top: buttonPosition.top,
        left: buttonPosition.left,
        width: buttonPosition.width,
        height: buttonPosition.height,
      });
    }
  };

  useEffect(() => {
    // To scroll to top of the screen
    window.scrollTo(0, 0);

    const devicesCountPerPage = parseInt(
      window.localStorage.getItem("devicesListPerPage") ?? "10"
    );
    setDevicesPageLimit(devicesCountPerPage);
    setDevicesPageNumber(1);
    handlePhaseSelectTotalPages(selectedPhase);
    setShowFilterDropdown(false);
    setShowFilterButtonDropdown(false);
    setStagesList(deviceStagesList);
    setFilters([]);
    resetDeviceSelectionStates();
    setSelectedStages(["all"]);
    fetchStatusDetails(1, devicesCountPerPage, "all");
  }, [selectedPhase, JSON.stringify(actionsData?.schedule?.phases?.length)]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardContainer>
      <StyledHeader as="h2" style={{ marginBottom: "30px" }}>
        Devices List
      </StyledHeader>

      {/* filter component to filter devices with stages */}
      {!hideFilterButton && (
        <NewActionLabelContainer style={{ marginBottom: "20px" }}>
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                minHeight: "35px",
              }}
            >
              <div
                className="dashboard-header"
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <FlexContainer>
                  <div>
                    <ActionButtonV3
                      id="device-list-filters-button"
                      onClick={() => {
                        setShowFilterDropdown(true);
                        getButtonPosition("device-list-filters-button");
                      }}
                      border_style={"dashed"}
                      label={"Filters"}
                      icon={"filter"}
                      margin_left="0px"
                    />
                    <div style={{ position: "relative" }}>
                      {showFilterDropdown && (
                        <ActionFilterDropdown
                          dropdownOptions={stagesList}
                          setShowFilterDropdown={(value) =>
                            setShowFilterDropdown(value)
                          }
                          addFilter={(filter) => addDeviceFilter(filter)}
                          removeFilter={(filter) =>
                            removeDeviceFilter(filter.name)
                          }
                          selectedValues={selectedStages}
                        />
                      )}
                    </div>
                  </div>
                </FlexContainer>
              </div>
            </div>
            {Object.keys(filters)?.length !== 0 && (
              <div
                style={{
                  alignSelf: "center",
                }}
              >
                <StyledNonBoldHeader
                  as="h3"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "1.1rem",
                    whiteSpace: "nowrap",
                  }}
                  className={`${
                    Object.keys(filters)?.length === 0
                      ? "color-disabled"
                      : "selectable-item underline hover-underline color-blue"
                  }`}
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Clear All
                </StyledNonBoldHeader>
              </div>
            )}
          </div>
        </NewActionLabelContainer>
      )}

      {filters?.length !== 0 ? (
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginBottom: "10px",
              marginRight: "10px",
              marginLeft: "10px",
              width: "100%",
            }}
          >
            {filters.map((filter, key) => (
              <SelectedFilter
                key={key}
                filterName={"status"}
                filterValues={[filter.filter]}
                filterNameIcon={"info circle"}
                filterValueColor={filter.color}
                onCrossClick={(value, array) => removeDeviceFilter(value)}
              />
            ))}

            <AddFiltersButton
              id={"device-list-add-filters-button"}
              name="plus"
              onClick={() => {
                setShowFilterButtonDropdown(true);
                getButtonPosition("device-list-add-filters-button");
              }}
            />
            <div style={{ position: "relative" }}>
              {showFilterButtonDropdown && (
                <ActionFilterDropdown
                  dropdownOptions={stagesList}
                  setShowFilterDropdown={(value) =>
                    setShowFilterButtonDropdown(value)
                  }
                  addFilter={(filter) => addDeviceFilter(filter)}
                  removeFilter={(filter) => removeDeviceFilter(filter.name)}
                  selectedValues={selectedStages}
                  parentButtonPosition={addFiltersButtonPosition}
                />
              )}
            </div>
            <Divider />
          </div>
          <ThinDivider />
        </>
      ) : (
        <></>
      )}

      {permissions.allowedActions.includes(actionsData.type) && (
        <SelectedDevicesAction
          // Selected devices
          allDevicesSelected={allDevicesSelected}
          selectedDevices={selectedDevices}
          selectedDeviceCount={selectedDeviceCount}
          // Data related
          areFiltersApplied={filters?.length > 0}
          selectedPhase={selectedPhase}
          actionID={actionID}
          actionsData={actionsData}
          handleRefetchDeviceStatusDetails={handleRefetchDeviceStatusDetails}
        />
      )}

      <ThinDivider />

      <DeviceListV3
        allDevicesSelected={allDevicesSelected}
        selectedDevices={selectedDevices}
        handleAllDevicesSelectedClick={handleAllDevicesSelectedClick}
        handleCheckboxClick={handleCheckboxClick}
        allowedActions={allowedActions}
        deviceData={deviceItems}
        actionID={actionID}
        actionsData={actionsData}
        loading={devicesLoading}
        handleRefetchDeviceStatusDetails={handleRefetchDeviceStatusDetails}
      />
      {!devicesLoading && deviceItems?.length !== 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: "16px",
          }}
        >
          <StyledPagination
            boundaryRange={0}
            defaultActivePage={devicesPageNumber}
            ellipsisItem={null}
            siblingRange={2}
            totalPages={Math.ceil(numTotal / devicesPageLimit)}
            onPageChange={onPaginationChange}
          />
          <StyledCardSearchPageInput
            icon="search"
            placeholder="Jump to page..."
            name="activePage"
            min={1}
            onChange={handlePaginationInputChange}
            onKeyDown={handlePaginationInputKeyDown}
            type="number"
            value={inputPageNumber ? inputPageNumber : ""}
          />

          <StyledSecondaryDevicePerPageWidget>
            <MenuItem>Devices per page</MenuItem>
            <MenuItem style={{ padding: "0px" }}>
              <SelectDevicesPerPage
                compact
                selection
                options={devicesPerPageOptions}
                value={devicesPageLimit}
                onChange={changeDevicesPerPage}
              />
            </MenuItem>
          </StyledSecondaryDevicePerPageWidget>
        </div>
      )}
    </CardContainer>
  );
});

export default memo(DeviceListView);
