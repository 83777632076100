import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  Button,
  DropdownDivider,
  DropdownHeader,
} from "semantic-ui-react";
import styled from "styled-components";
import { objectToQuery } from "../../../common/QuerySelector";
import {
  ClickhouseToTSTypes,
  getOperatorsOptionsByDataType,
  getTypeFromClickhouseToTS,
  isInputValueColumnTypeValid,
  User,
  validateWholeNumber,
} from "../../../../../util";
import {
  FetchStreamsAPIResponse,
  Filters,
  searchDevicesFilters,
} from "../../../../../BytebeamClient";
import * as uuid from "uuid";
import { capitalizeFirstLetter } from "../../../util";
import { queryOperators } from "../../../Dashboards/util";
import { beamtoast } from "../../../../common/CustomToast";
import DevicesFilterSearchInput from "../../../../common/StyledComponents/SearchInput";
import uniq from "lodash/uniq";
import DevicesFiltersTabRow from "./DevicesFiltersTabRow";
import { withRouter, RouteComponentProps } from "react-router-dom";

type DeviceFiltersOptionsType = {
  key: string;
  text: string;
  label?: { content: string; circular: boolean; size: string };
  value: `${"state" | "metadata"}:${string}`;
  passingvalue: string;
};

export interface FilterState {
  id: string;
  field: string;
  columnType: ClickhouseToTSTypes;
  type: "metadata" | "state";
  operator: string;
  isNullOperator: boolean;
  value: string | null;
}

const StyledDropdown = styled(Dropdown)`
  & .item {
    .label {
      min-width: 4rem;
    }
    .text {
      word-break: break-word;
    }
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 15px;
`;

const FilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const buildSearchQuery = (filters: FilterState[]): searchDevicesFilters => {
  const stateFilters: FilterState[] = filters.filter(
    (f) => f.type === "state" && f.field && f.value
  );
  const metadataFilters: FilterState[] = filters.flatMap((f) => {
    if (f.type === "metadata" && f.field && f.value) {
      if (f.isNullOperator) {
        return [
          {
            ...f,
            value: f.operator === "=" ? null : "", // sending null for IS NULL and empty string for IS NOT NULL
          },
        ];
      }
      return [f];
    }
    return [];
  });

  const query: searchDevicesFilters = {};

  if (stateFilters.length > 0) {
    // Group state filters by field, operator and value.
    const stateQuery = stateFilters.map((f) => ({
      column: f.field,
      operator: f.operator,
      isNullOperator: f.isNullOperator,
      columnType: f.columnType,
      value: String(f.value),
    }));
    // Converting object to query which is expected
    query.stateFilters = objectToQuery(stateQuery);
  }

  if (metadataFilters.length > 0) {
    // Group filters while maintaining the string[] type
    const groupedFilters: Filters = metadataFilters.reduce((acc, f) => {
      if (!acc[f.field]) {
        acc[f.field] = [];
      }
      acc[f.field].push(f.value !== null ? String(f.value) : f.value);
      return acc;
    }, {} as Filters);

    // Convert arrays with only one item to a single value, except for the "id" key, which should always be an array.
    query.metaDatafilters = Object.keys(groupedFilters).reduce((acc, key) => {
      const values = groupedFilters[key];
      acc[key] = values.length === 1 && key !== "id" ? values[0] : values;
      return acc;
    }, {});
  }

  return query;
};

/**
 * Get the fields options for the dropdown based on the metadata keys and state keys, and the serial key.
 * Label is the full label when searching in dropdown, else show the one letter label.
 * @param serialMetadataKey - The serial key of the metadata.
 * @param stateKeys - The state keys of the device.
 * @param metadataKeys - The metadata keys of the device.
 */
const getFieldsOptions = (
  serialMetadataKey: string | null | undefined,
  stateKeys: string[],
  metadataKeys: string[]
): DeviceFiltersOptionsType[] => {
  /**
   * Creates a dropdown option for device filters.
   *
   * @param key - The key for the dropdown option.
   * @param type - The type of the filter, either "state" or "metadata".
   * @returns An object representing the dropdown option for device filters.
   */
  const createDropdownOption = (
    key: string,
    type: "state" | "metadata"
  ): DeviceFiltersOptionsType => {
    const label = type === "state" ? "Shadow" : "Metadata";
    return {
      key: uuid.v4(),
      // Show full label when searching in dropdown, else show the one letter label.
      label: { content: label, circular: true, size: "tiny" },
      text: capitalizeFirstLetter(key),
      value: `${type}:${key}`,
      passingvalue: key,
    };
  };

  const deviceShadowOptions = stateKeys
    .filter((key) => key !== "timestamp" && key !== "insert_timestamp")
    .map((key) => createDropdownOption(key, "state"));

  const keyOptions = [
    ...metadataKeys,
    ...(serialMetadataKey && !metadataKeys.includes(serialMetadataKey)
      ? [serialMetadataKey]
      : []),
  ].map((key) => createDropdownOption(key, "metadata"));

  // Add the device ID option to the dropdown, and add a divider and header for the metadata and device shadow options.
  const options = uniq([
    {
      key: "id",
      text: "Device ID",
      value: "metadata:id",
      passingvalue: "id",
    } as DeviceFiltersOptionsType,
    keyOptions.length > 0 && {
      key: "divider1",
      value: "divider1",
      content: <DropdownDivider />,
      disabled: true,
    },
    keyOptions.length > 0 && {
      key: "header1",
      text: <DropdownHeader icon="tag" content="Metadata" />,
      value: "header1",
      disabled: true,
    },
    ...keyOptions,
    stateKeys.length > 0 && {
      key: "divider2",
      value: "divider2",
      content: <DropdownDivider />,
      disabled: true,
    },
    deviceShadowOptions.length > 0 && {
      key: "header2",
      text: <DropdownHeader icon="barcode" content="Device Shadow" />,
      value: "header2",
      disabled: true,
    },
    ...deviceShadowOptions,
  ]).filter(Boolean);

  return options;
};

type FilterProps = {
  readonly user: User;
  readonly stateKeys: any;
  readonly metadataKeys: any;
  readonly detailedStreamsList: FetchStreamsAPIResponse;
  readonly onSearch: (filter: any) => void;
  devicesLoading: boolean;
  showRefreshButton: boolean;
  refreshDevices: () => void;
} & RouteComponentProps;

const DevicesFilterUI: React.FC<FilterProps> = ({
  user,
  stateKeys,
  metadataKeys,
  detailedStreamsList,
  onSearch,
  devicesLoading,
  showRefreshButton,
  refreshDevices,
  history, // Access history from props
  location, // Access location from props
}) => {
  const serialMetadataKey = user?.["tenant-settings"]?.["serial-key"];

  // Create a ref for the input element
  const inputRef = useRef<HTMLInputElement>(null);

  const [fieldsOptions, setFieldsOptions] =
    useState<DeviceFiltersOptionsType[]>();
  const [filters, setFilters] = useState<FilterState[]>([]);
  const [currentFilter, setCurrentFilter] = useState<FilterState>({
    id: uuid.v4(),
    field: "id",
    columnType: "int",
    type: "metadata",
    operator: "=",
    isNullOperator: false,
    value: "",
  });

  const updateUrlWithFilters = (filtersToUpdate: FilterState[]): void => {
    const searchParams = new URLSearchParams(location.search);

    if (filtersToUpdate.length > 0) {
      const filtersStr = JSON.stringify(filtersToUpdate);
      searchParams.set("filters", filtersStr);
    } else {
      searchParams.delete("filters");
    }

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const loadFiltersFromUrl = (): FilterState[] => {
    const searchParams = new URLSearchParams(location.search);
    const filtersStr = searchParams.get("filters");

    if (filtersStr) {
      try {
        return JSON.parse(filtersStr);
      } catch (error) {
        console.error("Error parsing filters from URL:", error);
        return [];
      }
    }

    return []; // fall back to empty array if no filters are found in url
  };

  // Load filters from URL when component mounts
  useEffect(() => {
    const filtersFromUrl = loadFiltersFromUrl();

    if (filtersFromUrl && filtersFromUrl.length > 0) {
      setFilters(filtersFromUrl);
      onSearch(buildSearchQuery(filtersFromUrl));
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps
  // only runs when URL query string changes

  /**
   * Retrieves the TypeScript type corresponding to a given device shadow field.
   *
   * @param {string} field - The name of the device shadow field.
   * @returns {string} - The TypeScript type of the specified field.
   */
  const getDeviceShadowFieldType = (field: string): ClickhouseToTSTypes => {
    if (field === "id") {
      return "int";
    }
    const columnMeta = detailedStreamsList["device_shadow"]?.fields?.[field];
    const columnType = columnMeta?.type || "string";
    return getTypeFromClickhouseToTS(columnType);
  };

  // Format the field and type to correctly display the value in the dropdown.
  const formatCurrentFilterFieldValue = (
    filterType: FilterState["type"],
    filterField: FilterState["field"]
  ): DeviceFiltersOptionsType["value"] => {
    return `${filterType}:${filterField}`;
  };

  const handleFieldChange = (_, data) => {
    // Split the value to get the field and type, e.g. "metadata:id" -> ["metadata", "id"]
    const [fieldType, fieldValue]: ["metadata" | "state", string] =
      data.value.split(":");
    setCurrentFilter({
      ...currentFilter,
      field: fieldValue,
      columnType: getDeviceShadowFieldType(fieldValue),
      type: fieldType,
      // Reset operator if the field changes or if the field is a metadata field.
      operator:
        fieldType === "metadata" || currentFilter.field !== fieldValue
          ? "="
          : currentFilter.operator,

      // Reset value if the field changes.
      value: currentFilter.field === fieldValue ? currentFilter.value : "",
    });
  };

  const handleOperatorChange = (_, data) => {
    if (data.value === "IS NULL" || data.value === "IS NOT NULL") {
      setCurrentFilter({
        ...currentFilter,
        columnType: "null",
        operator: data.value === "IS NULL" ? "=" : "not=",
        isNullOperator: true,
        value: "null",
      });
    } else {
      setCurrentFilter({
        ...currentFilter,
        operator: data.value,
      });
    }
  };

  const handleValueChange = (e) => {
    setCurrentFilter({
      ...currentFilter,
      value: e.target.value.toString(),
    });
  };

  /**
   * Adds a filter to the list of filters and triggers a search with the updated filters.
   * Resets the UI, current filter to a new filter.
   */
  const addFilter = () => {
    // Validate the filter for device Id field and check if the value is a whole number.
    if (
      !currentFilter.field ||
      !currentFilter.value ||
      (currentFilter.field === "id" &&
        !validateWholeNumber(currentFilter.value))
    ) {
      // Show error toast if the device Id is not a whole number.
      beamtoast.error("Please enter whole number for device Id");
      return;
    }
    if (
      isInputValueColumnTypeValid(currentFilter.value, currentFilter.columnType)
    ) {
      const updatedFilters = [...filters, currentFilter];
      setFilters(updatedFilters);
      setCurrentFilter({
        id: uuid.v4(),
        field: currentFilter.field ?? serialMetadataKey ?? "id",
        columnType: getDeviceShadowFieldType(
          currentFilter.field ?? serialMetadataKey ?? "id"
        ),
        type: currentFilter.field ? currentFilter.type : "metadata",
        operator: "=",
        isNullOperator: false,
        value: "",
      });
      // Update URL with filters applied
      updateUrlWithFilters(updatedFilters);
      onSearch(buildSearchQuery(updatedFilters));
    }
  };

  // Add filter on Enter key press
  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && currentFilter.value) addFilter();
  };

  // Clear the input value and reset the current filter value.
  const handleClearInputClick = () => {
    setCurrentFilter({ ...currentFilter, value: "" });
    // Reset the input value
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const removeFilter = (filterId: string) => {
    const newFilters = filters.filter((filter) => filter.id !== filterId);
    setFilters(newFilters);

    // Update URL with removed filter
    updateUrlWithFilters(newFilters);

    if (newFilters.length === 0) {
      setCurrentFilter({
        id: uuid.v4(),
        field: serialMetadataKey ?? "id",
        columnType: getDeviceShadowFieldType(serialMetadataKey ?? "id"),
        type: "metadata",
        operator: "=",
        isNullOperator: false,
        value: "",
      });
      onSearch("");
    } else {
      const searchQuery = buildSearchQuery(newFilters);
      onSearch(searchQuery);
    }
  };

  const resetFilters = () => {
    setCurrentFilter({
      id: uuid.v4(),
      field: serialMetadataKey ?? "id",
      columnType: getDeviceShadowFieldType(serialMetadataKey ?? "id"),
      type: "metadata",
      operator: "=",
      isNullOperator: false,
      value: "",
    });
    setFilters([]);
    // Clear URL parameters when resetting filters
    updateUrlWithFilters([]);
    onSearch("");
  };

  useEffect(() => {
    if (serialMetadataKey) {
      setCurrentFilter({
        id: uuid.v4(),
        field: serialMetadataKey,
        columnType: getDeviceShadowFieldType(serialMetadataKey),
        type: "metadata",
        operator: "=",
        isNullOperator: false,
        value: "",
      });
    }
  }, [serialMetadataKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Focus on the input field when the field or operator changes.
    if (inputRef?.current) {
      inputRef.current?.focus();
    }
  }, [currentFilter.field, currentFilter.operator]);

  // Get the fields options for the dropdown based on the metadata keys and state keys, and the serial key.
  useEffect(() => {
    setFieldsOptions(
      getFieldsOptions(serialMetadataKey, stateKeys, metadataKeys)
    );
  }, [stateKeys, metadataKeys, serialMetadataKey]);

  return (
    <FilterContainer>
      <DevicesFiltersTabRow
        filters={filters}
        resetFilters={resetFilters}
        removeFilter={removeFilter}
        devicesLoading={devicesLoading}
        showRefreshButton={showRefreshButton}
        refreshDevices={refreshDevices}
      />
      <FilterRow
        key={currentFilter.id}
        style={{ marginTop: `${filters.length === 0 ? "10px" : "0px"}` }}
      >
        <StyledDropdown
          // z-index is added to overcome the device-selection tag being visible through the dropdown.
          style={{ width: "30%", zIndex: 95 }}
          placeholder="Select Field"
          search
          fluid
          selection
          options={fieldsOptions}
          value={formatCurrentFilterFieldValue(
            currentFilter.type,
            currentFilter.field
          )}
          onChange={handleFieldChange}
        />
        <Dropdown
          style={{ width: "12%", zIndex: 95 }}
          placeholder="Operator"
          selection
          options={
            currentFilter.type !== "metadata"
              ? getOperatorsOptionsByDataType(
                  queryOperators,
                  //using the original field type from the current field
                  getDeviceShadowFieldType(currentFilter.field)
                )
              : [
                  {
                    key: "contains",
                    value: "=",
                    text: "contains",
                  },
                  {
                    key: "IS NULL",
                    value: "IS NULL",
                    text: "IS NULL",
                  },
                  {
                    key: "IS NOT NULL",
                    value: "IS NOT NULL",
                    text: "IS NOT NULL",
                  },
                ]
          }
          value={currentFilter.operator}
          onChange={handleOperatorChange}
          onClose={() => {
            // Focus on the input field when the operator dropdown closes.
            if (inputRef?.current) {
              inputRef.current?.focus();
            }
          }}
        />
        <DevicesFilterSearchInput
          ref={inputRef}
          name="search_input"
          style={{ width: "100%" }}
          type={"text"}
          icon={
            currentFilter?.value
              ? {
                  name: "close",
                  link: true,
                  onClick: handleClearInputClick,
                }
              : undefined
          }
          value={currentFilter.value}
          placeholder={`Find Device by ${fieldsOptions?.find((f) => f.passingvalue === currentFilter.field)?.text}...`}
          onChange={handleValueChange}
          onKeyPress={handleEnterKeyPress}
        />
        <Button
          disabled={!currentFilter?.value}
          icon="search"
          primary
          onClick={addFilter}
        />
      </FilterRow>
    </FilterContainer>
  );
};

export default withRouter(React.memo(DevicesFilterUI));
